import { ButtonVariants } from '../Button/styled'

import { PushContactProps } from '.'

export const pushContactMocks: PushContactProps = {
  title: 'On vous attend.',
  text: "Commencez l'aventure dès maintenant.",
  cta: {
    label: 'Nous contacter',
    link: '/contact',
    variant: ButtonVariants.secondary,
  },
  image: {
    src: '/images/home/pushcontact/us.png',
    alt: 'Nous contacter',
  },
}
