import styled, { css } from 'styled-components'
import Image from 'next/image'

import Button from '../Button'
import { mq, sizes } from '../../theme'
import Icon from '../Icon'

export const Container = styled.section`
  position: relative;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndexes.default};
  position: relative;
`

export const Content = styled.div`
  width: 100%;
  margin-right: 2rem;
  @media ${mq(sizes.tablet)} {
    max-width: 58%;
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.textStyles.titleH1};
  color: ${({ theme }) => theme.colors.primary.pureBlack};
  margin: 0;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.text};
  color: ${({ theme }) => theme.colors.primary.grey};
  margin: 1rem 0 2rem 0;
`

export const CTA = styled(Button)``

export const Right = styled.div`
  position: relative;
  display: none;

  @media ${mq(sizes.tablet)} {
    display: block;
  }

  & > img {
    border-radius: 1rem;
  }
`

export const SpotsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.sub};
`

export const SpotsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Spot1 = styled(Image)`
  position: absolute;
  top: 0;
  left: 40%;
  filter: blur(5rem);
`

export const Spot2 = styled(Image)`
  position: absolute;
  bottom: -30%;
  right: 3%;
  filter: blur(6rem);
`

export const StarsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const StarsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const StarStyle = css`
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
`

export const Star1 = styled(Icon)`
  ${StarStyle};
`

export const Star2 = styled(Icon)`
  ${StarStyle};
  top: 80%;
  left: 10%;
`

export const Star3 = styled(Icon)`
  ${StarStyle};
  bottom: 30%;
  right: 40%;
`

export const Star4 = styled(Icon)`
  ${StarStyle};
  top: -5%;
  right: 0%;
`
