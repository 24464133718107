import { FC } from 'react'
import cx from 'classnames'
import { ImageProps } from 'next/image'

import { ButtonProps } from '../Button'
import { Icons } from '../Icon'

import * as SC from './styled'

export type PushContactProps = {
  className?: string
  title: string
  text: string
  cta: ButtonProps
  image: ImageProps
}

const PushContact: FC<PushContactProps> = (props) => {
  const { className, title, text, cta, image } = props

  return (
    <SC.Container className={cx('PushContact', className)}>
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        <SC.Cta {...cta}>{cta.label}</SC.Cta>
      </SC.Content>
      <SC.Img {...image} width={500} height={400} />
      <SC.LeftBottom
        src="/images/home/pushcontact/left-bottom.png"
        alt="left-bottom"
        width={300}
        height={200}
      />
      <SC.RightTop
        src="/images/home/pushcontact/right-top.png"
        alt="right-top"
        width={300}
        height={200}
      />
      <SC.SpotsContainer>
        <SC.SpotsWrapper>
          <SC.Spot1
            src="/images/home/arguments/spot1.png"
            alt="Red spot"
            width={317}
            height={230}
          />
        </SC.SpotsWrapper>
      </SC.SpotsContainer>
      <SC.StarsContainer>
        <SC.StarsWrapper>
          <SC.Star1 icon={Icons.starWhite} />
          <SC.Star2 icon={Icons.starWhite} />
        </SC.StarsWrapper>
      </SC.StarsContainer>
    </SC.Container>
  )
}

export default PushContact
