import { Icons } from '../Icon'

import { ArgumentListProps } from '.'

export const argumentListMock: ArgumentListProps = {
  title: "D'une idée à un projet concret !",
  text: 'Chez Pioss, nous sommes convaincus que la technologie est un outil puissant pour propulser vos idées vers le succès.',
  items: [
    {
      icon: {
        icon: Icons.littleStar,
      },
      title: 'Des outils puissants',
      text: 'Nous utilisons les dernières technologies pour développer des sites web et applications mobiles sécurisées et performantes. (React, NextJS, NodeJS, Go, Docker, Kubernetes...)',
    },
    {
      icon: {
        icon: Icons.littleStar,
      },
      title: 'Un accompagnement sur mesure',
      text: 'Nous vous accompagnons dans la réalisation de vos projets, de la conception à la mise en production.',
    },
    {
      icon: {
        icon: Icons.cube,
      },
      title: 'Des prix compétitifs',
      text: 'Nous proposons un rapport qualité/prix imbattable. Nous vous garantissons des projets de qualité à des prix compétitifs.',
    },
    {
      icon: {
        icon: Icons.cube,
      },
      title: 'Une vision complète',
      text: "Grâce à nos outils de monitoring, vous aurez un compte rendu détaillé de l'état de vos applications en continu.",
    },
    {
      icon: {
        icon: Icons.cube2,
      },
      title: 'Transparence et communication',
      text: 'Vous serez informé de chaque étape de votre projet, assurant ainsi une collaboration efficace et une satisfaction totale.',
    },
    {
      icon: {
        icon: Icons.cube2,
      },
      title: 'Adaptabilité et évolutivité',
      text: 'Nous mettons tout en œuvre pour nous adapter à vos exigences et anticiper les tendances du marché.',
    },
  ],
}
