import React, { FC, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { useTransition } from '@react-spring/web'
import { useIntersectionObserver, useWindowSize } from 'usehooks-ts'

import { IconProps, Icons } from '../Icon'
import { sizes } from '../../theme'

import * as SC from './styled'

export type ArgumentListProps = {
  className?: string
  title: string
  text: string
  items: {
    icon: IconProps
    title: string
    text: string
  }[]
}

const ArgumentList: FC<ArgumentListProps> = (props) => {
  const { className, title, text, items } = props
  const size = useWindowSize()
  const [isMobile, setIsMobile] = useState(false)
  const triggerRef = useRef(null)
  const observed = useIntersectionObserver(triggerRef, {})
  const isVisible = !!observed?.isIntersecting

  const [animatedItems, api] = useTransition(items, () => ({
    from: {
      transform: 'translate3d(0,-40px,0)',
      display: 'inline-block',
      opacity: 0,
    },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
    trail: 200,
  }))

  useEffect(() => {
    if (isVisible) {
      api.start()
    }
  }, [isVisible, api])

  useEffect(() => {
    setIsMobile(size?.width < sizes.mobile)
  }, [size?.width])

  return (
    <SC.Container className={cx('ArgumentsList', className)}>
      <SC.Wrapper>
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        {isMobile && <div ref={triggerRef} />}
        <SC.Arguments>
          {animatedItems((style, argument, _null, index) => (
            <SC.Argument style={style} key={index}>
              <SC.ArgumentTitle>
                <SC.ArgumentIcon {...argument.icon} />
                {argument.title}
              </SC.ArgumentTitle>
              <SC.ArgumentText>{argument.text}</SC.ArgumentText>
            </SC.Argument>
          ))}
        </SC.Arguments>
        {!isMobile && <div ref={triggerRef} />}
      </SC.Wrapper>
      <SC.SpotsContainer>
        <SC.SpotsWrapper>
          <SC.Spot1
            src="/images/home/arguments/spot1.png"
            alt="Red spot"
            width={317}
            height={230}
          />
        </SC.SpotsWrapper>
      </SC.SpotsContainer>
      <SC.StarsContainer>
        <SC.StarsWrapper>
          <SC.Star1 icon={Icons.star} />
        </SC.StarsWrapper>
      </SC.StarsContainer>
    </SC.Container>
  )
}

export default ArgumentList
