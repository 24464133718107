import Image from 'next/image'
import { keyframes, styled } from 'styled-components'

export const FullCircle = styled(Image)``

const move = keyframes`
  0% {
    transform: translate(-7rem, 6rem) rotate(8deg);
  }
  100% {
    transform: translate(5.5rem, -6rem) rotate(8deg);
  }
`

export const Rocket = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${move} 2s ease-out forwards;
`

export const MidCircle = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
`
