import { FC } from 'react'
import cx from 'classnames'

import { ButtonProps } from '../Button'
import { Icons } from '../Icon'

import * as SC from './styled'

export type HeroProps = {
  className?: string
  title: string
  text: string
  cta?: ButtonProps
  rightChildren?: React.ReactNode
}

const Hero: FC<HeroProps> = (props) => {
  const { className, title, text, cta, rightChildren } = props

  return (
    <SC.Container className={cx('Hero', className)}>
      <SC.Wrapper>
        <SC.Content>
          <SC.Title>{title}</SC.Title>
          <SC.Text>{text}</SC.Text>
          {cta && <SC.CTA {...cta} />}
        </SC.Content>
        {rightChildren && <SC.Right>{rightChildren}</SC.Right>}
      </SC.Wrapper>
      <SC.SpotsContainer>
        <SC.SpotsWrapper>
          <SC.Spot1
            src="/images/home/hero/spot1.png"
            alt="Red spot"
            width={317}
            height={230}
            priority
          />
          <SC.Spot2
            src="/images/home/hero/spot2.png"
            alt="Red spot"
            width={400}
            height={215}
            priority
          />
        </SC.SpotsWrapper>
      </SC.SpotsContainer>
      <SC.StarsContainer>
        <SC.StarsWrapper>
          <SC.Star1 icon={Icons.star} />
          <SC.Star2 icon={Icons.star} />
          <SC.Star3 icon={Icons.star} />
          <SC.Star4 icon={Icons.star} />
        </SC.StarsWrapper>
      </SC.StarsContainer>
    </SC.Container>
  )
}

export default Hero
