import { FC, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { useWindowSize } from 'usehooks-ts'
import { ImageProps } from 'next/image'

import { sizes } from '../../theme'
import { Icons } from '../Icon'

import * as SC from './styled'

export type Testimony = {
  title: string
  details: string
  author: {
    name: string
    avatar: ImageProps
  }
  id: number
  angle: number
}

export type TestimoniesProps = {
  className?: string
  title: string
  items: Testimony[]
}

const NORMAL_SPEED = 0.25
const TRANSITION_SPEED = 10
const RADIUS = 250
const MOBILE_RADIUS = 150

const Testimonies: FC<TestimoniesProps> = (props) => {
  const { className, title, items } = props
  const size = useWindowSize()
  const [selected, setSelected] = useState<Testimony>(items[0])

  const sunRef = useRef<HTMLImageElement>(null)

  const orbits = useMemo<Testimony[]>(
    () => items.filter((item) => item !== selected),
    [items, selected]
  )

  const orbitsRef = useRef<Testimony[]>(items)

  const [centerX, setCenterX] = useState(0)
  const [centerY, setCenterY] = useState(0)
  const radius = size?.width > sizes.mobile ? RADIUS : MOBILE_RADIUS
  const [speed, setSpeed] = useState(NORMAL_SPEED)

  const calculateCenter = () => {
    if (!sunRef.current) return

    const x = sunRef.current.offsetLeft + sunRef.current.offsetWidth / 2
    const y = sunRef.current.offsetTop + sunRef.current.offsetHeight / 2

    setCenterX(x)
    setCenterY(y)
  }

  const animateOrbits = () => {
    orbitsRef.current = orbitsRef.current.map((orbit) => ({
      ...orbit,
      angle: (orbit.angle + speed) % 360,
    }))

    orbitsRef.current.forEach((orbit) => {
      const x = centerX + radius * Math.cos(orbit.angle * (Math.PI / 180))
      const y = centerY + radius * Math.sin(orbit.angle * (Math.PI / 180))

      const orbitElement = document.getElementById(`orbit${orbit.id}`)
      if (orbitElement) {
        orbitElement.style.transform = `translate(${
          x - orbitElement.offsetWidth / 2
        }px, ${y - orbitElement.offsetHeight / 2}px)`
      }
    })
  }

  const progressiveSpeed = () => {
    setSpeed(TRANSITION_SPEED)
    setTimeout(() => {
      setSpeed(NORMAL_SPEED)
    }, 1000)
  }

  const selectOrbit = (orbit: Testimony) => {
    setSelected((prev) => {
      const orbitInRef = orbitsRef.current.find((item) => item.id === orbit.id)

      orbitsRef.current = orbitsRef.current.map((item) => {
        if (item.id === prev.id) {
          return {
            ...item,
            angle: orbitInRef?.angle || item.angle,
          }
        }

        return item
      })

      return orbit
    })
    progressiveSpeed()
  }

  useEffect(() => {
    calculateCenter()

    let animationFrameId: number

    const animate = () => {
      animateOrbits()
      animationFrameId = requestAnimationFrame(animate)
    }

    animationFrameId = requestAnimationFrame(animate)

    return () => {
      cancelAnimationFrame(animationFrameId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerX, centerY, speed, size?.width])

  return (
    <SC.Container className={cx('Testimonies', className)}>
      <SC.Title>{title}</SC.Title>
      <SC.Content>
        <SC.Universe>
          <SC.Circle
            src="/images/home/hero/full.png"
            alt="Circle"
            width={700}
            height={700}
          />
          <SC.CurrentAvatar
            {...selected.author.avatar}
            width={200}
            height={200}
            alt={selected.author.name}
            ref={sunRef}
          />
          <SC.UnselectedAvatars>
            {orbits.map((item) => (
              <div key={item.id} id={`orbit${item.id}`}>
                <SC.UnselectedAvatar
                  {...item.author.avatar}
                  width={100}
                  height={100}
                  alt={item.author.name}
                  onClick={() => selectOrbit(item)}
                />
              </div>
            ))}
          </SC.UnselectedAvatars>
        </SC.Universe>
        <SC.Testimony>
          <SC.TestimonyTitle>{selected.title}</SC.TestimonyTitle>
          <SC.TestimonyDetails>{selected.details}</SC.TestimonyDetails>
          <SC.TestimonyAuthors>
            {items.map((item, index) => (
              <SC.TestimonyAuthor
                key={index}
                onClick={() => selectOrbit(item)}
                $isSelected={item === selected}
              >
                <SC.TestimonyAuthorAvatar
                  {...item.author.avatar}
                  width={40}
                  height={40}
                  alt={item.author.name}
                />
              </SC.TestimonyAuthor>
            ))}
          </SC.TestimonyAuthors>
          <SC.TestimonyAuthorName>
            {selected.author.name}
          </SC.TestimonyAuthorName>
        </SC.Testimony>
        <SC.SpotsContainer>
          <SC.SpotsWrapper>
            <SC.Spot1
              src="/images/home/arguments/spot1.png"
              alt="Red spot"
              width={317}
              height={230}
            />
          </SC.SpotsWrapper>
        </SC.SpotsContainer>
        <SC.StarsContainer>
          <SC.StarsWrapper>
            <SC.Star1 icon={Icons.star} />
            <SC.Star2 icon={Icons.star} />
          </SC.StarsWrapper>
        </SC.StarsContainer>
      </SC.Content>
    </SC.Container>
  )
}

export default Testimonies
