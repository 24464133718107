import { NextPage } from 'next'
import { t } from 'i18next'

import { initWithLangAndLabels } from '../i18n'
import { PageProps } from '../relay/common/PageProps'
import { MainLayout, MainLayoutProps } from '../layouts'
import { getHeaderProps } from '../relay/common/Header'
import Hero from '../components/Hero'
import { heroHomeMock } from '../components/Hero/mocks'
import ArgumentList from '../components/ArgumentList'
import { argumentListMock } from '../components/ArgumentList/mocks'
import Testimonies, { TestimoniesProps } from '../components/Testimonies'
import PushContact from '../components/PushContact'
import { pushContactMocks } from '../components/PushContact/mocks'
import { getFooterProps } from '../relay/common/Footer'
import RestApiClient from '../strapi/client'
import testimonials from '../strapi/requests/testimonials'
import { app } from '../configuration'
import { REVALIDATE_1_HOUR } from '../utils/Cache'

type Props = PageProps &
  MainLayoutProps & {
    data: {
      testimonials: TestimoniesProps
    }
  }

const Page: NextPage<Props> = ({ headerProps, footerProps, data }) => {
  return (
    <MainLayout headerProps={headerProps} footerProps={footerProps}>
      <Hero {...heroHomeMock} />
      <ArgumentList {...argumentListMock} />
      <Testimonies {...data.testimonials} />
      <PushContact {...pushContactMocks} />
    </MainLayout>
  )
}

export default Page

export async function getStaticProps() {
  try {
    const i18n = initWithLangAndLabels('fr', {})

    const [testimonialsRs] = await Promise.all([
      RestApiClient.call(testimonials()),
    ])

    const data = {
      testimonials: {
        title: t('testimonials_title'),
        subtitle: t('testimonials_subtitle'),
        items: testimonialsRs?.data,
      },
    }

    return {
      props: {
        data,
        headerProps: getHeaderProps('/'),
        footerProps: getFooterProps(),
        i18n,
        metas: {
          title: 'Pioss - Créateurs de solutions web et mobiles',
          description:
            'Agence de développement digital innovante et performante en France.',
          url: app.APP_URL,
        },
        revalidate: REVALIDATE_1_HOUR,
      },
    }
  } catch (error) {
    return {
      notFound: true,
      props: {
        errors: error,
      },
    }
  }
}
