import Image from 'next/image'

import { Icons } from '../Icon'

import { HeroProps } from '.'
import Rocket from './Rocket'

export const heroHomeMock: HeroProps = {
  title: 'Créateurs de solutions web et mobiles',
  text: 'Agence de développement digital innovante et performante en France.',
  cta: {
    label: 'Nous contacter',
    icon: {
      icon: Icons.arrowRight,
    },
    link: '/contact',
  },
  rightChildren: <Rocket />,
}

export const heroCarrerMock: HeroProps = {
  title: 'Envie de nous rejoindre ?',
  text: 'Vous avez l’âme d’un aventurier et souhaitez nous rejoindre pour de nouvelles aventures ! Alors sautez le pas et aidez nous à construire l’avenir !',
  rightChildren: (
    <Image
      src="/images/carriere/hero.jpg"
      alt="Hero banner"
      width={500}
      height={350}
    />
  ),
}
