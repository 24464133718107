import Image from 'next/image'
import { css, styled } from 'styled-components'

import { mq, sizes } from '../../theme'
import Icon from '../Icon'

export const Container = styled.section``

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2};
  color: ${({ theme }) => theme.colors.primary.pureBlack};
  margin: 0;
  text-align: center;
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.text};
  color: ${({ theme }) => theme.colors.primary.pureBlack};
  text-transform: uppercase;
  text-align: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${mq(sizes.tablet)} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const Universe = styled.div`
  position: relative;
  width: 100%;
  min-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mq(sizes.tablet)} {
    width: 70%;
    max-width: 70rem;
    min-height: 70rem;
  }
`

export const Circle = styled(Image)`
  position: absolute;
  z-index: -1;
`

const avatarStyle = css`
  border-radius: 50%;
  border: 0.5rem solid ${({ theme }) => theme.colors.primary.pureBlack};
  object-fit: cover;
`

export const CurrentAvatar = styled(Image)`
  ${avatarStyle};
  width: 21rem;
  height: 21rem;
`

export const UnselectedAvatars = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const UnselectedAvatar = styled(Image)`
  ${avatarStyle};
  width: 9rem;
  height: 9rem;
  cursor: pointer;
`

export const Testimony = styled.div`
  max-width: 50rem;
`

export const TestimonyTitle = styled.h3`
  ${({ theme }) => theme.textStyles.titleH3};
  margin: 0 0 1.2rem 0;
`

export const TestimonyDetails = styled.p`
  ${({ theme }) => theme.textStyles.text};
  color: ${({ theme }) => theme.colors.primary.grey};
`

export const TestimonyAuthors = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 2rem 0;
`

export const TestimonyAuthor = styled.li<{ $isSelected: boolean }>`
  margin: 0 1rem;
  cursor: pointer;
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0.5)};
  transform: ${({ $isSelected }) => ($isSelected ? 'scale(1.4)' : 'scale(1)')};
  transition: all 0.3s ease-in-out;
`

export const TestimonyAuthorAvatar = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  width: 3rem;
  height: 3rem;
`

export const TestimonyAuthorName = styled.p`
  ${({ theme }) => theme.textStyles.text};
  color: ${({ theme }) => theme.colors.primary.pureBlack};
  font-weight: 900;
`

export const SpotsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.sub};
`

export const SpotsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Spot1 = styled(Image)`
  position: absolute;
  top: 40%;
  left: 20%;
  filter: blur(5rem);

  @media ${mq(sizes.tablet)} {
    left: 55%;
  }
`

export const StarsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.sub};
`

export const StarsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const StarStyle = css`
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
`

export const Star1 = styled(Icon)`
  ${StarStyle};
  top: 10%;
  right: 0;
`

export const Star2 = styled(Icon)`
  ${StarStyle};
  top: 80%;
  left: 50%;
`
