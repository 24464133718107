import styled, { css } from 'styled-components'
import Image from 'next/image'
import { animated } from '@react-spring/web'

import Icon from '../Icon'
import { mq, sizes } from '../../theme'

export const Container = styled.section`
  position: relative;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndexes.default};
  position: relative;
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2};
  margin-bottom: 1rem;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.text};
  color: ${({ theme }) => theme.colors.primary.red};
`

export const Arguments = styled(animated.ul)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0;

  @media ${mq(sizes.tablet)} {
    &:nth-child(1) {
      margin-right: 2rem;
    }
  }
`

export const Argument = styled(animated.li)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  width: 100%;

  @media ${mq(sizes.tablet)} {
    width: 49%;
  }
`

export const ArgumentTitle = styled.h3`
  ${({ theme }) => theme.textStyles.titleH4};
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

export const ArgumentIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`

export const ArgumentText = styled.p`
  ${({ theme }) => theme.textStyles.text};
  color: ${({ theme }) => theme.colors.primary.grey};
`

export const SpotsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const SpotsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Spot1 = styled(Image)`
  position: absolute;
  top: 30%;
  left: 30%;
  filter: blur(5rem);
`

export const StarsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const StarsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const StarStyle = css`
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
`

export const Star1 = styled(Icon)`
  ${StarStyle};
  top: 10%;
  right: 0;
`
