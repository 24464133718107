import { FC } from 'react'

import * as SC from './styled'

const Rocket: FC = () => {
  return (
    <>
      <SC.FullCircle
        src="/images/home/hero/full.png"
        alt="Circle"
        width={600}
        height={600}
        priority
      />
      <SC.Rocket
        src="/images/home/hero/rocket.png"
        alt="Rocket"
        width={500}
        height={500}
        priority
      />
      <SC.MidCircle
        src="/images/home/hero/mid.png"
        alt="Circle"
        width={600}
        height={600}
        priority
      />
    </>
  )
}

export default Rocket
