import { styled, css } from 'styled-components'
import Image from 'next/image'

import Button from '../Button'
import { mq, sizes } from '../../theme'
import Icon from '../Icon'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary.pureBlack};
  border-radius: 0.8rem;
  position: relative;
  padding: 2rem 2rem 0 2rem;

  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 7rem 0 7rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;

  @media ${mq(sizes.tablet)} {
    align-items: flex-start;
    text-align: left;
    margin-bottom: 0;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2}
  color: ${({ theme }) => theme.colors.primary.pureWhite};
  margin: 0 0 1rem 0;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme }) => theme.colors.primary.pureWhite};
`

export const Cta = styled(Button)`
  margin-top: 2rem;
  align-self: center;
  z-index: ${({ theme }) => theme.zIndexes.default};

  @media ${mq(sizes.tablet)} {
    align-self: flex-start;
  }
`

export const Img = styled(Image)`
  z-index: ${({ theme }) => theme.zIndexes.default};
`

export const LeftBottom = styled(Image)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const RightTop = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const SpotsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const SpotsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Spot1 = styled(Image)`
  position: absolute;
  top: 30%;
  right: 1%;
  filter: blur(4rem);
`

export const StarsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const StarsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const StarStyle = css`
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
`

export const Star1 = styled(Icon)`
  ${StarStyle};
  top: 2%;
  right: 25%;
`

export const Star2 = styled(Icon)`
  ${StarStyle};
  top: 70%;
  left: 40%;
  transform: rotate(45deg);
`
